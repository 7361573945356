import * as Yup from 'yup';

export const createVehicleSchema = Yup.object().shape({
  company: Yup.string().required('Company is required'),
  branch: Yup.string().required('Branch is required'),
  forms: Yup.array().of(
    Yup.object().shape({
      rego: Yup.string().required('Rego No. is required'),
      fleetNumber: Yup.string(),
      make: Yup.string(),
      model: Yup.string(),
      vehicleType: Yup.string().required('Vehicle type is required'),
      uploads: Yup.array().of(
        Yup.object().shape({
          name: Yup.string().required('Required'),
          description: Yup.string(),
        }),
      ),
    }),
  ),
});

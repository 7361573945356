import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from 'react-router-dom';
import AppRoot from '@/routes/AppRoot';
import Login from '@/pages/auth/Login';
import ForgotPassword from '@/pages/auth/ForgotPassword';
import ResetPassword from '@/pages/auth/ResetPassword';
import ErrorPage from '@/pages/ErrorPage';
import { ProtectedRoute } from '@/hooks/useAuth';
import Configurations from '@/pages/Configurations';
import Profile from '@/pages/Profile';
import TeamsCreate from '@/pages/TeamsCreate';
import Teams from '@/pages/Teams';
import VehiclesEdit from '@/pages/VehiclesEdit';
import Vehicles from '@/pages/Vehicles';
import Dashboard from '@/pages/Dashboard';
import Permits from '@/pages/Permits';
import PublicRoute from '@/PublicRoute';
import CreateConfiguration from '@/pages/CreateConfiguration';
import EditConfiguration from '@/pages/EditConfiguration';
import GlobalLoadingIndicator from '@/components/GlobalLoadingIndicator';
import ViewConfiguration from '@/pages/ViewConfiguration';

export type PageRoute = {
  path: string;
  name: string;
  element: JSX.Element;
  errorElement: JSX.Element;
};
export default function AppRouter() {
  const router = createBrowserRouter([
    {
      path: '/',
      element: <Navigate to="/login" replace />,
      errorElement: <ErrorPage />,
    },
    {
      path: 'login',
      element: (
        <PublicRoute>
          <Login />
        </PublicRoute>
      ),
      errorElement: <ErrorPage />,
    },
    {
      path: 'forgot-password',
      element: (
        <PublicRoute>
          <ForgotPassword />
        </PublicRoute>
      ),
      errorElement: <ErrorPage />,
    },
    {
      path: 'reset-password',
      element: (
        <PublicRoute>
          <ResetPassword />
        </PublicRoute>
      ),
      errorElement: <ErrorPage />,
    },
    {
      element: (
        <ProtectedRoute>
          <AppRoot />
        </ProtectedRoute>
      ),
      children: [
        {
          path: '/dashboard',
          element: <Dashboard />,
          errorElement: <ErrorPage />,
        },
        {
          path: '/companies/:companyId/configurations/view',
          element: <ViewConfiguration />,
          errorElement: <ErrorPage />,
        },
        {
          path: '/vehicles',
          element: <Vehicles />,
          errorElement: <ErrorPage />,
        },
        {
          path: '/vehicles/:vehicleId',
          element: <VehiclesEdit />,
          errorElement: <ErrorPage />,
        },
        {
          path: '/vehicles/new',
          element: <Configurations />,
          errorElement: <ErrorPage />,
        },
        {
          path: '/vehicles/:vehicleId/configurations/new',
          element: <CreateConfiguration />,
          errorElement: <ErrorPage />,
        },
        {
          path: '/configurations/view/:configurationId',
          element: <ViewConfiguration />,
          errorElement: <ErrorPage />,
        },
        {
          path: '/configurations/edit/:configurationId',
          element: <EditConfiguration />,
          errorElement: <ErrorPage />,
        },
        {
          path: '/configurations/edit/:configurationId/:permitId',
          element: <EditConfiguration />,
          errorElement: <ErrorPage />,
        },
        {
          path: '/teams',
          element: <Teams />,
          errorElement: <ErrorPage />,
        },
        {
          path: '/teams/create',
          element: <TeamsCreate />,
          errorElement: <ErrorPage />,
        },
        {
          path: '/profile',
          element: <Profile />,
          errorElement: <ErrorPage />,
        },
        {
          path: '/permits',
          element: <Permits />,
          errorElement: <ErrorPage />,
        },
      ],
    },
  ]);

  return (
    <>
      <GlobalLoadingIndicator />
      <RouterProvider router={router} />
    </>
  );
}

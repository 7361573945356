export const isDateExpired = (expiryDate: Date) => {
  const currentDate = new Date(Date.now());
  return expiryDate < currentDate;
}
  
export const isDateExpiring = (expiryDate: Date) => {
  const now = new Date();
  const tenDaysInMilliseconds = 10 * 24 * 60 * 60 * 1000; // 10 days in milliseconds
  const tenDaysLater = new Date(now.getTime() + tenDaysInMilliseconds);
  return expiryDate <= tenDaysLater && expiryDate >= now;
}
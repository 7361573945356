import React, { useRef, useState } from 'react';
import { alpha } from '@mui/material/styles';
import SaveIcon from '@/components/icons/SaveIcon';
import { createConfigurationSchema } from '@/lib/validation/configurations';
import {
  Box,
  Button,
  CircularProgress,
  darken,
  Divider,
  Fab,
  Grid,
  InputLabel,
  Link,
  Menu,
  MenuItem,
  Paper,
  styled,
  TextField,
  Typography,
} from '@mui/material';
import { Field, FieldArray, Formik, FormikHelpers, FormikProps } from 'formik';
import {
  CurrentFiles,
  FileDropZone,
  PDatePicker,
  PTextField,
  SnackBarContext,
} from 'pragmatic-ui';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { MenuProps } from '@mui/material/Menu';
import { useNavigate, useParams } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import {
  useConfigurations,
  useUpdateConfiguration,
} from '@/hooks/useConfigurations';
import { useContext } from 'react';
import { v4 as uuidV4 } from 'uuid';
import { useCompanies } from '@/hooks/useCompanies';
import { TYPE_OPTIONS } from '@/constants';
import CloseIcon from '@mui/icons-material/Close';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DatePickerIcon from '@/components/icons/DatePickerIcon';
import { format } from 'date-fns';
import {isDateExpired, isDateExpiring} from '@/lib/dates';

const StyledForm = styled('form')(() => ({
  width: '100%',
}));

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'left',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 140,
    color:
      theme.palette.mode === 'light'
        ? 'rgb(55, 65, 81)'
        : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '8px 0',
      margin: '0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));

const FileRender = ({
  file,
  onRemoveFileClick,
}: {
  file: CurrentFiles;
  onStarClick: () => void;
  onRemoveFileClick: () => void;
  removeEntry?: boolean;
}) => {
  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        justifyItems="flex-start"
        sx={{
          windth: '325px',
          minWidth: '325px',
        }}
      >
        <Typography
          variant="caption"
          color="primary"
          sx={{
            color: 'primary',
            textAlign: 'left',
          }}
        >
          {file.filename}
        </Typography>
        <Link
          onClick={onRemoveFileClick}
          sx={{
            color: 'primary',
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            mr: 12,
          }}
        >
          Remove
        </Link>
      </Box>
    </>
  );
};

export default function EditConfigurationForm() {
  const formikRef = useRef<FormikProps<any>>(null);
  const fileUploadButtonRefs = useRef<(HTMLButtonElement | null)[]>([]);
  const { configurationId, permitId } = useParams();
  const navigate = useNavigate();
  const { data: configurations = [], isLoading: isConfigurationsLoading } =
    useConfigurations();
  const updateConfiguration = useUpdateConfiguration();
  const { showSnack } = useContext(SnackBarContext);
  const { data: _, isLoading: isCompaniesLoading } = useCompanies();
  const [anchorMoreMenu, setAnchorMoreMenu] =
    React.useState<null | HTMLElement>(null);
  const openMoreMenu = Boolean(anchorMoreMenu);
  const [currentRow, setCurrentRow] = React.useState(0);
  const onClickMoreMenu = (
    event: React.MouseEvent<HTMLElement>,
    idx: number,
  ) => {
    setAnchorMoreMenu(event.currentTarget);
    setCurrentRow(idx);
  };
  const handleCloseMenu = () => {
    setAnchorMoreMenu(null);
  };

  const [fileFormatType, setFileFormatType] = useState(0);
  const fileFormatAll = {
    'application/pdf': ['.pdf'],
    'application/geo+json': ['.geojson'], // Add GeoJSON support
    'application/json': ['.geojson', '.json'], // Fallback to JSON MIME type
  };
  const fileFormatPdf = {
    'application/pdf': ['.pdf'],
  };
  const fileFormatJson = {
    'application/geo+json': ['.geojson'], // Add GeoJSON support
    'application/json': ['.geojson', '.json'], // Fallback to JSON MIME type
  };

  const isLoading = isConfigurationsLoading || isCompaniesLoading;
  if (isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  const configuration = configurations.find(
    (configuration) => configuration._id === configurationId,
  );

  const permits = configuration?.permits.map((permit) => {
    return {
      ...permit,
      issueDate: new Date(permit.issueDate),
      expiryDate: new Date(permit.expiryDate),
    };
  });

  const handleSubmit = async (
    formValues: any,
    { resetForm }: FormikHelpers<any>,
  ) => {
    const formData = new FormData();

    formData.append('_id', configurationId || '');
    formData.append('vehicle', formValues.vehicle);
    formData.append('name', formValues.name);
    formData.append('multiUnit', formValues.multiUnit.toString());

    formValues.forms[0].permits.forEach((permit: any, uploadIndex: number) => {
      const fileKey = `files-${uploadIndex}`;
      const files = formValues[fileKey] || [];
      files.forEach((file: any) => {
        const fileid = uuidV4();
        permit.fileids = permit.fileids || [];
        if (!permit.fileids.includes(fileid)) {
          permit.fileids.push(fileid);
          formData.append(fileid, file, file.name);
        }
      });
    });

    formData.append('uploads', JSON.stringify(formValues.forms[0].permits));

    try {
      // for (let [key, value] of formData.entries()) {
      //   console.log(`${key}:`, value);
      // }
      await updateConfiguration.mutateAsync(formData);
      resetForm();
      showSnack('Configuration created successfully', 'success');
      navigate(`/vehicles/${configuration?.vehicle}`);
    } catch (error) {
      console.error('Error creating configuration:', error);
      showSnack('Error creating configuration', 'error');
    }
  };

  const onClickRemoveRow = () => {
    if (formikRef.current) {
      handleCloseMenu();
      const { values, setFieldValue } = formikRef.current;
      const updatedPermits = values.forms[0].permits.filter(
        (_: any, idx: number) => idx !== currentRow,
      );
      setFieldValue(`forms.${0}.permits`, updatedPermits);
    }
  };

  const onClickRemoveAll = () => {
    if (formikRef.current) {
      formikRef.current.setFieldValue(
        `forms.${0}.permits.${currentRow}.uploads`,
        [],
      );
      const { values, setFieldValue } = formikRef.current;
      if (values[`files-${currentRow}`]) {
        setFieldValue(`files-${currentRow}`, undefined);
      }
    }
    handleCloseMenu();
  };

  const onClickUploadAll = () => {
    setFileFormatType(0);
    //const { values } = formikRef.current;
    setTimeout(() => {
      if (formikRef.current && fileUploadButtonRefs.current[currentRow]) {
        fileUploadButtonRefs.current[currentRow].click(); // Programmatically click the button
      }
    }, 300);
    handleCloseMenu();
  };

  const onClickUploadPermit = () => {
    setFileFormatType(1);
    //const { values } = formikRef.current;
    setTimeout(() => {
      if (formikRef.current && fileUploadButtonRefs.current[currentRow]) {
        fileUploadButtonRefs.current[currentRow].click(); // Programmatically click the button
        setFileFormatType(0);
      }
    }, 300);
    handleCloseMenu();
  };

  const onClickUploadJson = () => {
    setFileFormatType(2);
    //const { values } = formikRef.current;
    setTimeout(() => {
      if (formikRef.current && fileUploadButtonRefs.current[currentRow]) {
        fileUploadButtonRefs.current[currentRow].click(); // Programmatically click the button
        setFileFormatType(0);
      }
    }, 300);
    handleCloseMenu();
  };

  const onClickRemovePermit = () => {
    if (formikRef.current) {
      const { values, setFieldValue } = formikRef.current;
      const updatedUploads = values.forms[0].permits[currentRow].uploads.filter(
        (u: { file: { filename: string } }) => {
          const isPdf = u.file.filename.endsWith('.pdf');
          return !isPdf;
        },
      );
      setFieldValue(`forms.${0}.permits.${currentRow}.uploads`, updatedUploads);
    }
    handleCloseMenu();
  };

  const onClickRemoveJson = () => {
    if (formikRef.current) {
      const { values, setFieldValue } = formikRef.current;
      const updatedUploads = values.forms[0].permits[currentRow].uploads.filter(
        (u: { file: { filename: string } }) => {
          const isJson =
            u.file.filename.endsWith('.json') ||
            u.file.filename.endsWith('.geojson');
          return !isJson;
        },
      );
      setFieldValue(`forms.${0}.permits.${currentRow}.uploads`, updatedUploads);
    }
    handleCloseMenu();
  };

  const formikProps = {
    initialValues: {
      vehicle: configuration?.vehicle,
      name: configuration?.name,
      multiUnit: configuration?.multiUnit,
      forms: [
        {
          permits:
            permits !== undefined && permits.length > 0
              ? permits
              : [
                  {
                    rca: '',
                    type: '',
                    issueDate: new Date(),
                    expiryDate: new Date(),
                    //uploads:[{ file: {} }],
                  },
                ],
        },
      ],
    },
    validationSchema: createConfigurationSchema,
    onSubmit: handleSubmit,
  };


  return (
    <Box px={4}>
      <Formik innerRef={formikRef} {...formikProps}>
        {({ handleSubmit, setFieldValue, values }: FormikProps<any>) => {
          return (
            <>
              <StyledForm onSubmit={handleSubmit}>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Box display={'flex'} alignItems="center">
                    <PTextField
                      variant="standard"
                      autoComplete="off"
                      name={'name'}
                      placeholder="Configuration Name"
                      InputProps={{
                        disableUnderline: false,
                      }}
                      sx={{
                        width: '300px',
                      }}
                    />
                  </Box>
                  <Box display="flex" alignItems="center">
                    <Button
                      size="small"
                      sx={{
                        textTransform: 'uppercase',
                        fontWeight: '700',
                        mr: 2,
                      }}
                      onClick={() =>
                        navigate(`/vehicles/${configuration?.vehicle}`)
                      }
                    >
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      variant="contained"
                      size="small"
                      color="primary"
                      startIcon={<SaveIcon />}
                      sx={{ textTransform: 'uppercase', fontWeight: '700' }}
                    >
                      Save
                    </Button>
                  </Box>
                </Box>
                <>
                  <FieldArray name="forms">
                    {() => (
                      <>
                        {values.forms.map((form: any, index: number) => (
                          <Box py={4} key={`form-${index}`}>
                            <Grid container columnSpacing={8}>
                              <FieldArray name={`forms.${index}.permits`}>
                                {({
                                  remove: removePermit,
                                  push: pushPermit,
                                }: any) => (
                                  <>
                                    <Grid
                                      item
                                      xs={9}
                                      mt={8}
                                      key={`permits-${index}`}
                                    >
                                      <Typography
                                        variant="h6"
                                        sx={{ color: '#EAEAEA' }}
                                      >
                                        Attach Permits
                                      </Typography>
                                      <Paper
                                        component="div"
                                        sx={{
                                          py: 3,
                                          pl: 2,
                                          pr: 0,
                                          mt: 4,
                                          borderRadius: '16px',
                                        }}
                                      >
                                        {form.permits.map(
                                          (permit: any, idx: number) => (
                                            <Grid
                                              container
                                              columnSpacing={4}
                                              mt={idx > 0 ? 0 : 0}
                                              key={`permit-${index}-${idx}`}
                                            >
                                              <Grid item xs={2}>
                                                <Field
                                                  name={`forms.${index}.permits.${idx}.rca`}
                                                >
                                                  {({ field }: any) => (
                                                    <PTextField
                                                      {...field}
                                                      label={
                                                        idx == 0 && 'RCA'
                                                      }
                                                      placeholder="RCA"
                                                      fullWidth
                                                    />
                                                  )}
                                                </Field>
                                              </Grid>

                                              <Grid item xs={2}>
                                                <Field
                                                  name={`forms.${index}.permits.${idx}.type`}
                                                >
                                                  {({ field }: any) => (
                                                    <PTextField
                                                      {...field}
                                                      label={
                                                        idx == 0 && 'TYPE'
                                                      }
                                                      placeholder="Type"
                                                      fullWidth
                                                      select
                                                    >
                                                      {TYPE_OPTIONS.map(
                                                        (type) => (
                                                          <MenuItem
                                                            key={`type-${type}`}
                                                            value={type}
                                                          >
                                                            {type}
                                                          </MenuItem>
                                                        ),
                                                      )}
                                                    </PTextField>
                                                  )}
                                                </Field>
                                              </Grid>

                                              <Grid item xs={2}>
                                                <Field
                                                  name={`forms.${index}.permits.${idx}.issueDate`}
                                                >
                                                  {({ field }: { field: { name: string; value: Date; onChange: (event: React.ChangeEvent<HTMLInputElement>) => void; onBlur: (event: React.FocusEvent<HTMLInputElement>) => void; } }) => (
                                                    <LocalizationProvider
                                                      dateAdapter={
                                                        AdapterDateFns
                                                      }
                                                    >
                                                      <DesktopDatePicker
                                                        name={`forms.${index}.permits.${idx}.issueDate`}
                                                        value={field.value}
                                                        onChange={(date) => setFieldValue(field.name, date)}
                                                        label={
                                                          idx == 0 && 'ISSUED'
                                                        }
                                                        format="dd/MM/yyyy"
                                                        slots={{
                                                          openPickerIcon:
                                                            DatePickerIcon,
                                                        }}
                                                      />
                                                    </LocalizationProvider>
                                                  )}
                                                </Field>
                                              </Grid>
                                              <Grid item xs={2}>
                                                <Field
                                                  name={`forms.${index}.permits.${idx}.expiryDate`}
                                                >
                                                  {({ field }: { field: { name: string; value: Date; onChange: (event: React.ChangeEvent<HTMLInputElement>) => void; onBlur: (event: React.FocusEvent<HTMLInputElement>) => void; } }) => (
                                                    <LocalizationProvider
                                                      dateAdapter={
                                                        AdapterDateFns
                                                      }
                                                    >
                                                      <DesktopDatePicker
                                                        name={`forms.${index}.permits.${idx}.expiryDate`}
                                                        value={field.value}
                                                        onChange={(date) => setFieldValue(field.name, date)}
                                                        label={
                                                          idx == 0 && 'EXPIRY'
                                                        }
                                                        format="dd/MM/yyyy"
                                                        slots={{
                                                          openPickerIcon:
                                                            DatePickerIcon,
                                                        }}
                                                        sx={{
                                                          '& .MuiInputBase-root': {
                                                            backgroundColor: isDateExpired(field.value)? '#f44336 !important' : isDateExpiring(field.value)? '#906a2f !important' : '',
                                                          }
                                                        }}
                                                      />
                                                    </LocalizationProvider>
                                                  )}
                                                </Field>
                                              </Grid>

                                              <Grid item xs={3}>
                                                <InputLabel
                                                  sx={{
                                                    color: '#fff',
                                                  }}
                                                >
                                                  {
                                                    idx == 0 && 'UPLOADS'
                                                  }
                                                </InputLabel>

                                                <FileDropZone
                                                  name={`files-${idx}`}
                                                  maxFiles={20}
                                                  featured
                                                  fileFormat={
                                                    fileFormatType === 0
                                                      ? fileFormatAll
                                                      : fileFormatType === 1
                                                        ? fileFormatPdf
                                                        : fileFormatJson
                                                  }
                                                  renderTitle={(
                                                    openFilePicker,
                                                  ) =>
                                                    permit.uploads &&
                                                    permit.uploads.length >
                                                      0 ? (
                                                      permit.uploads.map(
                                                        (
                                                          u: any,
                                                          fileIdx: number,
                                                        ) => {
                                                          const { file } = u;
                                                          return (
                                                            <Grid
                                                              item
                                                              xs={6}
                                                              key={
                                                                file.uuid ||
                                                                `form-files-${idx}-${fileIdx}`
                                                              }
                                                            >
                                                              <Link
                                                                target="_blank"
                                                                href={
                                                                  file?.signedUrl
                                                                }
                                                                download={
                                                                  file.filename
                                                                }
                                                                sx={{
                                                                  color:
                                                                    'primary',
                                                                  mt: 2,
                                                                  display:
                                                                    'flex',
                                                                  justifyContent:
                                                                    'flex-start',
                                                                  alignItems:
                                                                    'center',
                                                                  textAlign:
                                                                    'left',
                                                                  width: '100%',
                                                                }}
                                                              >
                                                                {file.filename}
                                                              </Link>
                                                              {/*  */}
                                                            </Grid>
                                                          );
                                                        },
                                                      )
                                                    ) : (
                                                      <>
                                                        <Typography
                                                          variant="caption"
                                                          sx={{
                                                            fontSize: '14px',
                                                            ml: -7,
                                                          }}
                                                          onClick={() => {
                                                            setFileFormatType(
                                                              0,
                                                            );
                                                            setTimeout(() => {
                                                              if (
                                                                openFilePicker
                                                              ) {
                                                                openFilePicker();
                                                              }
                                                            }, 500);
                                                          }}
                                                        >
                                                          Drag and drop to
                                                          upload (max file size:
                                                          50 MB)
                                                        </Typography>
                                                      </>
                                                    )
                                                  }
                                                  renderButton={(
                                                    openFilePicker,
                                                  ) => (
                                                    <>
                                                      <Button
                                                        ref={(el) =>
                                                          (fileUploadButtonRefs.current[
                                                            idx
                                                          ] = el)
                                                        }
                                                        variant="contained"
                                                        color="primary"
                                                        sx={{
                                                          display: 'none',
                                                          mt: 2,
                                                          textTransform:
                                                            'uppercase',
                                                        }}
                                                        onClick={openFilePicker}
                                                      >
                                                        Upload
                                                      </Button>
                                                    </>
                                                  )}
                                                  renderFile={(
                                                    newFile,
                                                    onStarClick,
                                                    onRemoveFileClick,
                                                  ) => {
                                                    return (
                                                      <FileRender
                                                        file={newFile}
                                                        onStarClick={
                                                          onStarClick
                                                        }
                                                        onRemoveFileClick={
                                                          onRemoveFileClick
                                                        }
                                                      />
                                                    );
                                                  }}
                                                  containerSx={{
                                                    background: 'transparent',
                                                    px: 0,
                                                    mx: 0,
                                                  }}
                                                />
                                              </Grid>
                                              <Grid
                                                item
                                                xs={1}
                                                display="flex"
                                                justifyContent="center"
                                                alignItems="center"
                                              >
                                                {permit._id && (
                                                  <Fab
                                                    onClick={(event) => {
                                                      onClickMoreMenu(
                                                        event,
                                                        idx,
                                                      );
                                                    }}
                                                    color="primary"
                                                    size="small"
                                                    sx={{
                                                      mr: 2,
                                                      '&:hover': {
                                                        backgroundColor: darken(
                                                          '#F6AE2D',
                                                          0.2,
                                                        ),
                                                      },
                                                    }}
                                                  >
                                                    <MoreVertIcon />
                                                  </Fab>
                                                )}
                                                {!permit._id && (
                                                  <Fab
                                                    onClick={() => {
                                                      removePermit(idx);
                                                    }}
                                                    color="secondary"
                                                    size="small"
                                                    sx={{
                                                      mr: 2,
                                                      '&:hover': {
                                                        backgroundColor: darken(
                                                          '#F6AE2D',
                                                          0.2,
                                                        ),
                                                      },
                                                    }}
                                                  >
                                                    <CloseIcon />
                                                  </Fab>
                                                )}
                                              </Grid>
                                            </Grid>
                                          ),
                                        )}
                                      </Paper>
                                    </Grid>
                                    <Grid
                                      item
                                      xs={3}
                                      display="flex"
                                      direction={'column'}
                                      justifyContent={'flex-end'}
                                    >
                                      <Box
                                        display="flex"
                                        justifyContent="flex-start"
                                        alignContent="center"
                                        alignItems="center"
                                      >
                                        <Fab
                                          color="primary"
                                          size="small"
                                          sx={{
                                            mr: 2,
                                            '&:hover': {
                                              backgroundColor: darken(
                                                '#F6AE2D',
                                                0.2,
                                              ),
                                            },
                                          }}
                                          onClick={() => {
                                            pushPermit({
                                              rca: '',
                                              type: '',
                                              issueDate: new Date(),
                                              expiryDate: new Date(),
                                            });
                                          }}
                                        >
                                          <AddIcon />
                                        </Fab>
                                        <Typography sx={{ color: '#F6AE2D' }}>
                                          Add Another Permit
                                        </Typography>
                                      </Box>
                                    </Grid>
                                  </>
                                )}
                              </FieldArray>
                            </Grid>

                            <Grid container mt={8} columnSpacing={8}>
                              <Grid item xs={12}>
                                <Divider
                                  component="div"
                                  sx={{ backgroundColor: '#BD3825' }}
                                />
                              </Grid>
                            </Grid>
                          </Box>
                        ))}
                      </>
                    )}
                  </FieldArray>

                  <StyledMenu
                    id="moreMenu"
                    anchorEl={anchorMoreMenu}
                    open={openMoreMenu}
                    onClose={handleCloseMenu}
                  >
                    <MenuItem onClick={onClickRemoveRow} disableRipple>
                      Delete entire row
                    </MenuItem>
                    <MenuItem onClick={onClickRemoveAll} disableRipple>
                      Remove All
                    </MenuItem>
                    <MenuItem onClick={onClickUploadAll} disableRipple>
                      Upload All
                    </MenuItem>
                    <MenuItem onClick={onClickRemovePermit} disableRipple>
                      Remove Permit
                    </MenuItem>
                    <MenuItem onClick={onClickUploadPermit} disableRipple>
                      Upload Permit
                    </MenuItem>
                    <MenuItem onClick={onClickRemoveJson} disableRipple>
                      Remove geoJSON
                    </MenuItem>
                    <MenuItem onClick={onClickUploadJson} disableRipple>
                      Upload geoJSON
                    </MenuItem>
                  </StyledMenu>
                </>
              </StyledForm>
            </>
          );
        }}
      </Formik>
    </Box>
  );
}

import {
  useQuery,
  useMutation,
  useQueryClient,
  QueryKey,
  UseQueryOptions,
  UseMutationOptions,
} from '@tanstack/react-query';
import api from '@/lib/api-client';
import type { Vehicle } from '@/types/api';
import { useCallback, useContext } from 'react';
import { SnackBarContext } from 'pragmatic-ui';

const getVehicles = async (): Promise<Vehicle[]> => {
  const response = await api.get('/v1/admin/vehicles');
  return response.data;
};

const vehiclesKey = ['vehicles'];

export const useVehicles = (
  options?: Omit<
    UseQueryOptions<Vehicle[], Error, Vehicle[], QueryKey>,
    'queryKey' | 'queryFn'
  >,
) =>
  useQuery({
    queryKey: vehiclesKey,
    queryFn: getVehicles,
    ...options,
  });

async function createVehicle(payload: FormData): Promise<Vehicle> {
  const response = await api.post('/v1/admin/vehicles', payload);
  return response.data;
}

export const useCreateVehicle = (
  options?: Omit<UseMutationOptions<Vehicle, Error, FormData>, 'mutationFn'>,
) => {
  const queryClient = useQueryClient();
  const { showSnack } = useContext(SnackBarContext);

  const setVehicle = useCallback(
    (data: Vehicle) => queryClient.setQueryData(vehiclesKey, data),
    [queryClient],
  );

  return useMutation({
    mutationFn: createVehicle,
    ...options,
    onSuccess: (vehicle, ...rest) => {
      setVehicle(vehicle);
      queryClient.invalidateQueries({ queryKey: vehiclesKey });
      options?.onSuccess?.(vehicle, ...rest);
      showSnack('Successfully added new vehicle', 'success');
    },
    onError: () => {
      showSnack('There was an unexpected error, please try again', 'error');
    },
  });
};

async function updateVehicle(updatedVehicle: FormData): Promise<Vehicle> {
  const vehicleId = updatedVehicle.get('_id');
  if (!vehicleId) {
    throw new Error('Vehicle ID is required to update the vehicle.');
  }

  const response = await api.put(
    `/v1/admin/vehicles/${vehicleId}`,
    updatedVehicle,
  );

  return response.data;
}

export const useUpdateVehicle = (
  _options?: Omit<
    UseMutationOptions<Vehicle, Error, Partial<Vehicle>>,
    'mutationFn'
  >,
) => {
  const queryClient = useQueryClient();
  const { showSnack } = useContext(SnackBarContext);

  return useMutation({
    mutationFn: updateVehicle,
    onError: () => {
      showSnack('There was an error updating the vehicle', 'error');
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: vehiclesKey });
      showSnack('Successfully updated company', 'success');
    },
  });
};

async function deleteVehicle(id: string) {
  const response = await api.delete(`/v1/admin/vehicles/${id}`);
  return response.data;
}

export const useDeleteVehicle = (
  options?: Omit<UseMutationOptions<void, Error, string>, 'mutationFn'>,
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deleteVehicle,
    ...options,
    onSuccess: (...args) => {
      queryClient.invalidateQueries({ queryKey: vehiclesKey });
      options?.onSuccess?.(...args);
    },
  });
};

import { useNavigate, useParams } from 'react-router-dom';
import { Box, Button, darken, Fab, Typography } from '@mui/material';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import CustomDataGrid from '@/components/CustomDataGrid';
import { GridColDef } from '@mui/x-data-grid-pro';
import PencilOutlinedIcon from '@/components/icons/PencilOutlinedIcon';
import { useDeleteConfiguration } from '@/hooks/useConfigurations';
import TableDeleteActionWithConfirmation from '@/components/TableDeleteActionWithConfirmation';
import { isDateExpired, isDateExpiring } from '@/lib/dates';

export default function ConfigurationsTable({
  configurations,
}: {
  configurations: any[];
}) {
  const navigate = useNavigate();
  const { vehicleId } = useParams();
  const deleteConfiguration = useDeleteConfiguration();

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Name',
      minWidth: 400,
      flex: 1,
      renderCell: (params) => {
        return (
          <Box
            display="flex"
            justifyContent="start"
            alignItems="center"
            height="100%"
          >
            <Typography sx={{ fontWeight: '700', fontSize: '14px' }}>
              {params.value}
            </Typography>
          </Box>
        );
      },
    },
    {
      field: 'permitsTotal',
      headerName: 'Permits Total',
      headerAlign: 'center',
      minWidth: 250,
      renderCell: (params) => {
        return (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100%"
          >
            {params.row.permits.length}
          </Box>
        );
      },
    },
    {
      field: 'overduePermits',
      headerName: 'Overdue',
      headerAlign: 'center',
      minWidth: 250,
      // valueGetter: (_, row) => {
      //   return row.permits.reduce((acc: number, curr: any) => {
      //     const currentDate = new Date(Date.now());
      //     const expiryDate = new Date(curr.expiryDate);
      //     return expiryDate < currentDate ? acc + 1 : acc;
      //   }, 0);
      // },
      renderCell: (params) => {
        const count = params.row.permits.reduce((acc: number, curr: any) => {
          const expiryDate = new Date(curr.expiryDate);
          return isDateExpired(expiryDate) ? acc + 1 : acc;
        }, 0);
        return (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100%"
            sx={{
              backgroundColor: count > 0 ? '#8a3836' : 'transparent',
            }}
          >
            {count}
          </Box>
        );
      },
    },
    {
      field: 'upcomingPermits',
      headerName: 'Upcoming',
      headerAlign: 'center',
      minWidth: 250,
      // valueGetter: (_, row) => {
      //   return row.permits.reduce((acc: number, curr: any) => {
      //     const currentDate = new Date(Date.now());
      //     const expiryDate = new Date(curr.expiryDate);
      //     return expiryDate < currentDate ? acc + 1 : acc;
      //   }, 0);
      // },
      renderCell: (params) => {
        const count = params.row.permits.reduce((acc: number, curr: any) => {
          const expiryDate = new Date(curr.expiryDate);
          return isDateExpiring(expiryDate) ? acc + 1 : acc;
        }, 0);
        return (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100%"
            sx={{
              backgroundColor: count > 0 ? '#906a2f' : 'transparent',
            }}
          >
            {count}
          </Box>
        );
      },
    },
    {
      field: 'edit',
      headerName: 'Actions',
      headerAlign: 'center',
      minWidth: 150,
      flex: 1,
      renderCell: (params: any) => (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100%"
        >
          <Fab
            color="primary"
            size="small"
            aria-label="add"
            sx={{
              mr: 2,
              '&:hover': {
                backgroundColor: darken('#F6AE2D', 0.2),
              },
            }}
            onClick={() => {
              navigate(`/configurations/edit/${params.row._id}`);
            }}
          >
            <PencilOutlinedIcon />
          </Fab>
          <TableDeleteActionWithConfirmation
            title="Delete Configuration"
            message={
              <>
                <Typography>
                  This vehicle will no longer be visible by all associated
                  members.
                </Typography>
                <Typography>
                  Do you still wish to permanently delete this vehicle?
                </Typography>
              </>
            }
            handleDeleteClick={() => deleteConfiguration.mutate(params.row._id)}
          />
        </Box>
      ),
    },
  ];

  return (
    <Box>
      <Box
        pb={6}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="h6" sx={{ color: '#EAEAEA' }}>
          Configurations
        </Typography>
        <Button
          variant="contained"
          size="small"
          color="primary"
          startIcon={<AddOutlinedIcon />}
          onClick={() => {
            navigate(`/vehicles/${vehicleId}/configurations/new`);
          }}
          sx={{ textTransform: 'uppercase', fontWeight: '700' }}
        >
          Add New
        </Button>
      </Box>
      {configurations && configurations.length > 0 && (
        <CustomDataGrid
          getRowId={(row) => row._id}
          rows={configurations}
          columns={columns}
          filter={false}
          search={false}
        />
      )}
    </Box>
  );
}

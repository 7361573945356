export default function WaypointMarker({ style = {} }: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      style={style}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.99998 7.77148C4.99998 4.45778 7.68627 1.77148 11 1.77148C14.3137 1.77148 17 4.45778 17 7.77148C17 9.86446 15.8267 11.3614 14.6719 12.5642C14.3928 12.8548 14.1053 13.1376 13.8298 13.4085L13.7933 13.4444C13.5033 13.7296 13.2272 14.0019 12.9677 14.2754C12.4451 14.8262 12.0315 15.3394 11.7666 15.8691C11.6214 16.1595 11.3246 16.3429 11 16.3429C10.6753 16.3429 10.3785 16.1595 10.2333 15.8691C9.96848 15.3394 9.55484 14.8262 9.03223 14.2754C8.77271 14.0019 8.49667 13.7296 8.20669 13.4444L8.17018 13.4085C7.89467 13.1376 7.60716 12.8548 7.32811 12.5642C6.1733 11.3614 4.99998 9.86446 4.99998 7.77148ZM12.7143 7.77148C12.7143 8.71826 11.9468 9.48577 11 9.48577C10.0532 9.48577 9.2857 8.71826 9.2857 7.77148C9.2857 6.82471 10.0532 6.0572 11 6.0572C11.9468 6.0572 12.7143 6.82471 12.7143 7.77148Z"
        fill="black"
      />
      <path
        d="M5.34594 13.944C5.77905 13.753 5.97527 13.247 5.78421 12.8139C5.59314 12.3807 5.08714 12.1845 4.65403 12.3756C3.7944 12.7548 3.04498 13.2306 2.49879 13.8035C1.94841 14.3809 1.57141 15.0958 1.57141 15.9144C1.57141 17.5269 2.98255 18.7013 4.58733 19.4234C6.27256 20.1818 8.54055 20.6286 11 20.6286C13.4594 20.6286 15.7274 20.1818 17.4126 19.4234C19.0174 18.7013 20.4286 17.5269 20.4286 15.9144C20.4286 15.0958 20.0515 14.3809 19.5012 13.8035C18.955 13.2306 18.2056 12.7548 17.3459 12.3756C16.9128 12.1845 16.4068 12.3807 16.2158 12.8139C16.0247 13.247 16.2209 13.753 16.654 13.944C17.3813 14.2649 17.9176 14.6268 18.2604 14.9864C18.599 15.3416 18.7143 15.6539 18.7143 15.9144C18.7143 16.4321 18.2066 17.1863 16.7092 17.8601C15.2921 18.4978 13.2744 18.9144 11 18.9144C8.72555 18.9144 6.70782 18.4978 5.29081 17.8601C3.79334 17.1863 3.2857 16.4321 3.2857 15.9144C3.2857 15.6539 3.40097 15.3416 3.73958 14.9864C4.08239 14.6268 4.61868 14.2649 5.34594 13.944Z"
        fill="black"
      />
    </svg>
  );
}

export default function MobileCraneIcon() {
  return (
    <svg
      width="43"
      height="46"
      viewBox="0 0 43 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M41.6156 31.2908C40.3793 31.2908 35.3074 31.2904 34.5758 31.2904C33.6613 31.2904 32.4419 30.9345 32.4419 28.9515C32.4419 27.3651 32.4418 26.7183 32.4418 25.7354"
        stroke="#F6AE2D"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M39.1326 40.7309C39.1326 37.07 35.7066 35.6328 34.0676 35.6328C32.7564 35.6328 4.41217 35.6328 1.80001 35.6328V39.1977"
        stroke="#F6AE2D"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M28.4884 34.6281C28.4884 31.5604 28.4884 28.5871 28.4884 27.1634C28.4884 25.3839 29.7078 25.5871 30.5207 25.5871C31.1711 25.5871 38.3112 25.5871 41.8 25.5871V40.7256H39.2467"
        stroke="#F6AE2D"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.9175 41.6509L19.8816 41.6509"
        stroke="#F6AE2D"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M25.5114 41.6509L29.5413 41.6509"
        stroke="#F6AE2D"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <ellipse
        cx="8.02364"
        cy="41.8198"
        rx="2.25948"
        ry="2.26117"
        stroke="#F6AE2D"
        strokeWidth="2"
      />
      <ellipse
        cx="13.6582"
        cy="41.8198"
        rx="2.25948"
        ry="2.26117"
        stroke="#F6AE2D"
        strokeWidth="2"
      />
      <ellipse
        cx="23.2518"
        cy="41.8188"
        rx="2.25948"
        ry="2.26117"
        stroke="#F6AE2D"
        strokeWidth="2"
      />
      <ellipse
        cx="32.442"
        cy="41.8188"
        rx="2.25948"
        ry="2.26117"
        stroke="#F6AE2D"
        strokeWidth="2"
      />
      <path
        d="M36.3902 19.6618C36.3774 20.0715 36.8516 21.3247 38.0509 21.3247C39.2503 21.3247 39.8037 20.2038 39.8037 19.4058C39.8037 18.6077 39.8037 18.226 39.8037 18.1356C39.8037 17.9514 38.4729 16.2415 37.8074 15.4097V12.7993H41.8V15.4097L39.9957 17.6748"
        stroke="#F6AE2D"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.9025 34.3473C23.9025 34.0276 23.9025 35.129 23.9025 34.6374C23.9025 34.0228 23.4034 28.8402 18.2208 28.8402C14.0747 28.8402 4.20574 28.8402 1.8 28.8402V34.4883"
        stroke="#F6AE2D"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.2089 28.8078L8.71016 20.3091H1.8V28.8078"
        stroke="#F6AE2D"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.2146 22.8904L30.3645 4.74047L33.3527 1.75098C34.9139 3.31209 36.7569 5.15517 37.448 5.84618C39.4101 7.80834 39.7492 8.51442 39.7492 9.49981"
        stroke="#F6AE2D"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.4885 26.4878L36.3963 5.58008"
        stroke="#F6AE2D"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M28.2903 3.49658L34.6663 9.87255"
        stroke="#F6AE2D"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M24.7631 6.68457L31.1391 13.0605"
        stroke="#F6AE2D"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M39.7335 9.87207V12.3673"
        stroke="#F6AE2D"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
}

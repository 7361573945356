import React, { useState, useCallback } from 'react';
import {
  TextField,
  Autocomplete,
  Box,
  Fab,
  Typography,
  IconButton,
  InputLabel,
} from '@mui/material';
import CustomMap from './AnotherNewMap'; // todo: delete old versions
import { debounce } from 'lodash';
import WaypointMarker from './icons/WaypointMarkerIcon';
import SwapIcon from './icons/SwapIcon';
import HidePointsIcon from './icons/HidePointsIcon';
import StartLocationIcon from './icons/StartLocationIcon';
import EndLocationIcon from './icons/EndLocationIcon';

interface Suggestion {
  id: string;
  place_name: string;
  center: [number, number];
}

interface MapWithAutocompleteProps {
  geojsonData: any;
}

const MapWithAutocomplete: React.FC<MapWithAutocompleteProps> = ({
  geojsonData,
}) => {
  const [startInput, setStartInput] = useState('');
  const [endInput, setEndInput] = useState('');
  const [startSuggestions, setStartSuggestions] = useState<Suggestion[]>([]);
  const [endSuggestions, setEndSuggestions] = useState<Suggestion[]>([]);
  const [startPoint, setStartPoint] = useState<[number, number] | null>(null);
  const [endPoint, setEndPoint] = useState<[number, number] | null>(null);
  const [waypoints, setWaypoints] = useState<[number, number][]>([]);
  const [isWaypointMode, setIsWaypointMode] = useState(false);
  const [startValue, setStartValue] = useState<Suggestion | null>(null);
  const [endValue, setEndValue] = useState<Suggestion | null>(null);
  const [showHighRiskOnly, setShowHighRiskOnly] = useState(false);

  const toggleWaypointMode = () => {
    setIsWaypointMode(!isWaypointMode);
  };

  const accessToken =
    'pk.eyJ1Ijoia2FyYW5wMyIsImEiOiJjbHV3Mno4eGowY3BmMmtud3h2cWh0ZXEyIn0.Jpsmhecf2-ZSoAUAjzpRxw'; // Replace with your Mapbox access token

  const fetchSuggestions = async (
    input: string,
    setter: React.Dispatch<React.SetStateAction<Suggestion[]>>,
  ) => {
    const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(
      input,
    )}.json?access_token=${accessToken}&autocomplete=true&limit=5`;

    try {
      const response = await fetch(url);
      const data = await response.json();
      const suggestions: Suggestion[] = data.features.map((feature: any) => ({
        id: feature.id,
        place_name: feature.place_name,
        center: feature.center,
      }));
      setter(suggestions);
    } catch (error) {
      console.error('Error fetching suggestions:', error);
    }
  };

  // Debounced functions to prevent excessive API calls
  const debouncedFetchSuggestionsStart = useCallback(
    debounce((value: string) => {
      fetchSuggestions(value, setStartSuggestions);
    }, 500),
    [],
  );

  const debouncedFetchSuggestionsEnd = useCallback(
    debounce((value: string) => {
      fetchSuggestions(value, setEndSuggestions);
    }, 500),
    [],
  );

  return (
    <Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        marginBottom={2}
        marginTop={2}
        gap={2}
        sx={{
          position: 'absolute',
          zIndex: '1',
          marginTop: '20px',
          width: '60%',
          paddingLeft: '20px',
        }}
      >
        <Autocomplete<Suggestion>
          options={startSuggestions}
          getOptionLabel={(option) => option.place_name}
          filterOptions={(x) => x}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          inputValue={startInput}
          value={startValue}
          onInputChange={(_event, value) => {
            setStartInput(value);
            if (value.length > 2) {
              debouncedFetchSuggestionsStart(value);
            }
          }}
          onChange={(_event: any, value: Suggestion | null) => {
            if (value) {
              setStartPoint(value.center);
            } else {
              setStartPoint(null);
            }
            setStartValue(value);
          }}
          renderInput={(params) => (
            <>
              <TextField
                {...params}
                label={
                  <Box component="div" display="flex" justifyContent="start">
                    <StartLocationIcon />
                    <Typography
                      variant="body1"
                      sx={{ fontSize: '14px', fontWeight: 600, ml: 2 }}
                    >
                      Start Location
                    </Typography>
                  </Box>
                }
                variant="filled"
                fullWidth
              />
            </>
          )}
          style={{ width: '45%' }}
        />
        <IconButton
          sx={{ marginTop: '25px' }}
          onClick={() => {
            const tempStartPoint = startPoint;
            const tempStartInput = startInput;
            const tempStartValue = startValue;

            setStartPoint(endPoint);
            setStartInput(endInput);
            setStartValue(endValue);

            setEndPoint(tempStartPoint);
            setEndInput(tempStartInput);
            setEndValue(tempStartValue);
          }}
        >
          <SwapIcon />
        </IconButton>
        <Autocomplete<Suggestion>
          options={endSuggestions}
          getOptionLabel={(option) => option.place_name}
          filterOptions={(x) => x}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          inputValue={endInput}
          value={endValue}
          onInputChange={(_event, value) => {
            setEndInput(value);
            if (value.length > 2) {
              debouncedFetchSuggestionsEnd(value);
            }
          }}
          onChange={(_event: any, value: Suggestion | null) => {
            if (value) {
              setEndPoint(value.center);
            } else {
              setEndPoint(null);
            }
            setEndValue(value);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label={
                <Box component="div" display="flex" justifyContent="start">
                  <EndLocationIcon />
                  <Typography
                    variant="body1"
                    sx={{ fontSize: '14px', fontWeight: 600, ml: 2 }}
                  >
                    End Location
                  </Typography>
                </Box>
              }
              variant="filled"
              fullWidth
            />
          )}
          style={{ width: '45%' }}
        />
        <Fab
          variant="circular"
          onClick={toggleWaypointMode}
          sx={{
            backgroundColor: 'primary.main',
            position: 'absolute',
            left: '10px',
            top: '60vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Box display="flex" justifyContent="center" alignItems="center">
            <WaypointMarker
              style={
                isWaypointMode ? { position: 'relative', left: '4px' } : {}
              }
            />
            {isWaypointMode ? (
              <Typography
                variant="caption"
                style={{
                  color: '#000',
                  fontSize: '16px',
                  marginTop: '-25px',
                  textAlign: 'center',
                }}
              >
                +
              </Typography>
            ) : null}
          </Box>
        </Fab>
        <Fab
          variant="circular"
          onClick={() => setShowHighRiskOnly(!showHighRiskOnly)}
          sx={{
            backgroundColor: showHighRiskOnly ? 'secondary' : 'secondary',
            position: 'absolute',
            left: '10px',
            top: '70vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Box display="flex" justifyContent="center" alignItems="center">
            <HidePointsIcon
              circleStroke={showHighRiskOnly ? 'white' : 'black'}
            />
          </Box>
        </Fab>
      </Box>
      <CustomMap
        geojsonData={geojsonData}
        startPoint={startPoint}
        endPoint={endPoint}
        waypoints={waypoints}
        setWaypoints={setWaypoints}
        isWaypointMode={isWaypointMode}
        showHighRiskOnly={showHighRiskOnly}
      />
    </Box>
  );
};

export default MapWithAutocomplete;

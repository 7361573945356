import { createPragmaticTheme } from 'pragmatic-ui';
// import { darken, lighten } from '@mui/material/styles';
import { lighten } from '@mui/material/styles';

const theme = createPragmaticTheme({
  borderRadius: 0,
  options: {
    palette: {
      // mode: 'dark',
      primary: {
        main: '#F6AE2D',
        contrastText: '#202D38',
      },
      secondary: {
        main: '#FFFFFF29',
        contrastText: '#fff',
      },
      background: {
        default: '#121212',
        paper: '#202D38',
      },
      text: {
        primary: '#FFF',
        secondary: '#757575',
      },
    },
    typography: {
      fontFamily: '"Figtree", sans-serif',
      fontWeightLight: 400,
      fontWeightRegular: 500,
      fontWeightMedium: 600,
      fontWeightBold: 800,
      body1: {
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '22px' /* 137.5% */,
        letterSpacing: '0.25px',
        fontFeatureSettings: "'clig' off, 'liga' off",
        color: '#FFF',
      },
      body2: {
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '22px' /* 137.5% */,
        letterSpacing: '0.15px',
        fontFeatureSettings: "'clig' off, 'liga' off",
        color: '#FFF',
      },
      subtitle1: {
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 800,
        lineHeight: '24px' /* 137.5% */,
        letterSpacing: '0.5px',
        textTransform: 'uppercase',
        fontFeatureSettings: "'clig' off, 'liga' off",
        color: '#FFF',
      },
      subtitle2: {
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 800,
        lineHeight: 'normal',
        letterSpacing: '0.5px',
        textTransform: 'uppercase',
        fontFeatureSettings: "'clig' off, 'liga' off",
        color: '#FFF',
      },
      caption: {
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: 'normal',
        letterSpacing: '0.15px',
        fontFeatureSettings: "'clig' off, 'liga' off",
        color: '#FFF',
      },
      h1: {
        fontSize: '96px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: '112.032px' /* 116.7% */,
        fontFeatureSettings: "'clig' off, 'liga' off",
        color: '#FFF',
      },
      h2: {
        fontSize: '64px',
        fontStyle: 'normal',
        fontWeight: 800,
        lineHeight: '68px',
        fontFeatureSettings: "'clig' off, 'liga' off",
        color: '#FFF',
      },
      h3: {
        fontSize: '56px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: 'normal',
        fontFeatureSettings: "'clig' off, 'liga' off",
        color: '#FFF',
      },
      h4: {
        fontSize: '34px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: 'normal' /* 106.25% */,
        letterSpacing: '0.25px',
        fontFeatureSettings: "'clig' off, 'liga' off",
        color: '#FFF',
      },
      h5: {
        fontSize: '26px',
        fontStyle: 'normal',
        fontWeight: 800,
        lineHeight: 'normal',
        letterSpacing: '0.5px',
        fontFeatureSettings: "'clig' off, 'liga' off",
        color: '#FFF',
      },
      h6: {
        fontSize: '20px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '30px',
        letterSpacing: '0.15px',
      },
    },
    components: {
      MuiButton: {
        variants: [
          {
            props: { variant: 'contained', color: 'secondary' },
            style: {
              borderRadius: '26px',
              '&:hover': {
                backgroundColor: '#FFFFFF29',
              },
            },
          },
          {
            props: { variant: 'contained', color: 'primary' },
            style: {
              borderRadius: '26px',
              '&:hover': {
                backgroundColor: lighten('#F6AE2D', 0.09),
              },
            },
          },
        ],
        styleOverrides: {
          root: {
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 600,
            lineHeight: '20px',
            letterSpacing: '0.4px',
            textTransform: 'capitalize',
            paddingLeft: '20px',
            paddingRight: '20px',
            boxShadow: 'none',
            borderRadius: '26px',
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            backgroundColor: '#202D38',
          },
        },
      },
      MuiDrawer: {
        styleOverrides: {
          paper: {
            backgroundColor: '#202D38',
            borderRight: 'none !important',
          },
          root: {
            backgroundColor: 'transparent',
            '&.MuiDrawer-docked': {
              backgroundColor: 'transparent',
            },
          },
          docked: {
            backgroundColor: 'transparent',
          },
        },
      },
      MuiCard: {
        styleOverrides: {
          root: {
            backgroundColor: '#202D38',
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            fontWeight: '600',
            fontSize: '14px',
          },
        },
      },
      MuiListItemButton: {
        styleOverrides: {
          root: {
            padding: '16px',
            borderRadius: '12px !important',
            '&, & .MuiListItemIcon-root, & .MuiTypography-root': {
              lineHeight: 'normal !important',
              letterSpacing: '0.15px !important',
            },
            '&.Mui-selected, &.Mui-selected:hover': {
              backgroundColor: 'rgba(255, 255, 255, 0.16)',
            },
            '&:first-of-type.Mui-selected, &:first-of-type.Mui-selected:hover':
              {
                backgroundColor: 'rgba(255, 255, 255, 0.16)',
              },
            // '&:not(:first-of-type)': {
            //   '& .Mui-selected': {
            //     backgroundColor: 'rgba(255, 255, 255, 0.16)',
            //   },
            //   '&.Mui-selected, &.Mui-selected:hover': {
            //     backgroundColor: 'rgba(255, 255, 255, 0.16) !important',
            //   },
            // },
          },
        },
      },
      MuiInput: {
        styleOverrides: {
          underline: {
            '&:before': {
              borderBottomColor: '#fff',
            },
            '&:hover:not(.Mui-disabled):before': {
              borderBottomColor: '#fff',
            },
            '&:after': {
              borderBottomColor: '#F6AE2D',
            },
          },
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            color: '#fff !important',
            background: '#202D38',
            '&:hover': {
              background: 'rgba(255, 255, 255, 0.08) !important',
            },
            '&.Mui-selected': {
              backgroundColor: 'transparent !important',
              color: '#F6AE2D !important', // Optional: change text color for selected state if needed
            },
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            '&.MuiInputBase-root': {
              '&.MuiFilledInput-root': {
                background: 'rgba(255, 255, 255, 0.09)',
                border: 'none',
                borderRadius: '4px',
              },
            },
          },
        },
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            '&:focus': {
              outline: 'none',
              border: 'none',
            },
          },
        },
      },
      MuiButtonBase: {
        styleOverrides: {
          root: {
            '&:focus': {
              outline: 'none',
              border: 'none',
            },
          },
        },
      },
    },
  },
});

export default theme;

import CustomDataGrid from '@/components/CustomDataGrid';
import UpdateCompanyDrawer from '@/features/dashboard/UpdateCompanyDrawer';
import {
  Box,
  Chip,
  ChipProps,
  CircularProgress,
  darken,
  Fab,
  Typography,
} from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useCompanies, useDeleteCompany } from '@/hooks/useCompanies';
import { useVehicles } from '@/hooks/useVehicles';
import { useNavigate } from 'react-router-dom';
import { useConfigurations } from '@/hooks/useConfigurations';
import MapIcon from '@/components/icons/MapIcon';
import { isDateExpiring } from '@/lib/dates';
import TableDeleteActionWithConfirmation from '@/components/TableDeleteActionWithConfirmation';

interface TableChipProps extends ChipProps {
  labelText: string | number;
  labelIconColor?: string;
}

function TableChip({
  labelText,
  labelIconColor = 'primary',
  ...props
}: TableChipProps) {
  return (
    <Chip
      {...props}
      size="small"
      variant="filled"
      label={
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          sx={{ fontWeight: 800 }}
        >
          {labelText}
          <ChevronRightIcon
            sx={{ ml: 1, fontSize: '18px', color: labelIconColor }}
          />
        </Box>
      }
    />
  );
}

export default function ClientTable() {
  const { data: companies = [], isLoading: isLoadingCompanies } =
    useCompanies();
  const { data: vehicles = [], isLoading: isLoadingVehicles } = useVehicles();
  const { data: configurations = [], isLoading: isLoadingConfigurations } =
    useConfigurations();
  const navigate = useNavigate();
  const deleteCompany = useDeleteCompany();

  const isLoading =
    isLoadingCompanies || isLoadingVehicles || isLoadingConfigurations;
  if (isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  const columns = [
    { field: 'name', headerName: 'Name', minWidth: 300, flex: 1 },
    {
      field: 'branches',
      headerName: 'Branches',
      minWidth: 275,
      flex: 1,
      valueGetter: (params: any) => {
        return params.reduce(
          (acc: string, current: string, index: number, array: string[]) => {
            return acc + current + (index < array.length - 1 ? ', ' : '');
          },
          '',
        );
      },
    },
    {
      field: 'regions',
      headerName: 'Regions',
      minWidth: 275,
      flex: 1,
      valueGetter: (params: any) => {
        return params.reduce(
          (acc: string, current: string, index: number, array: string[]) => {
            return acc + current + (index < array.length - 1 ? ', ' : '');
          },
          '',
        );
      },
    },
    {
      field: 'vehiclesCount',
      headerName: '# Vehicles',
      minWidth: 150,
      flex: 1,
      renderCell: (params: any) => {
        const vehiclesCount =
          vehicles.filter((vehicle) => params.row._id === vehicle.company._id)
            ?.length || 0;

        const filteredVehiclesUrl = `/vehicles?companyId=${params.row._id}`;

        return (
          <TableChip
            color="secondary"
            labelText={vehiclesCount}
            labelIconColor="primary.main"
            onClick={() => navigate(filteredVehiclesUrl)}
            sx={{
              '&:hover': {
                backgroundColor: '#F26419', // Hover color
              },
            }}
          />
        );
      },
    },
    {
      field: 'upcomingExpiredPermits',
      headerName: '# Upcoming Expired Permits',
      minWidth: 200,
      flex: 1,
      renderCell: (params: any) => {
        const expiringPermitsCount =
          configurations
            .filter(({ company }) => company === params.row._id)
            .flatMap(({ permits }) => permits)
            .filter(({ expiryDate }) => isDateExpiring(new Date(expiryDate)))
            .length ?? 0;

        return (
          <TableChip
            color={'primary'}
            labelText={expiringPermitsCount}
            onClick={() => navigate('/permits?permitStatus=expiring')}
            disabled={expiringPermitsCount === 0 ? true : false}
          />
        );
      },
    },
    {
      field: 'map',
      headerName: 'Map',
      minWidth: 50,
      flex: 1,
      renderCell: (params: any) => {
        // todo: make this just one variable wherever it's used here.
        const vehiclesCount =
          vehicles.filter((vehicle) => params.row._id === vehicle.company._id)
            ?.length || 0;

        return (
          <>
            <Fab
              color="primary"
              size="small"
              aria-label="add"
              sx={{
                mr: 2,
                '&:hover': {
                  backgroundColor: darken('#F6AE2D', 0.2),
                },
              }}
              onClick={() => {
                navigate(`/companies/${params.row._id}/configurations/view`);
              }}
              disabled={vehiclesCount < 1}
            >
              <MapIcon />
            </Fab>
          </>
        );
      },
    },
    {
      field: 'edit',
      headerName: 'Edit',
      minWidth: 120,
      flex: 1,
      renderCell: (params: any) => {
        return (
          <>
            <UpdateCompanyDrawer
              company={companies.find(
                (company) => company._id === params.row._id,
              )}
            />
            <TableDeleteActionWithConfirmation
              title={'Delete Client'}
              message={
                <>
                  <Typography>
                    This client will no longer be visible.
                  </Typography>
                  <Typography>
                    Do you still wish to permanently delete this client?
                  </Typography>
                </>
              }
              handleDeleteClick={() => deleteCompany.mutate(params.row._id)}
            />
          </>
        );
      },
    },
  ];

  return (
    <CustomDataGrid
      rows={companies}
      columns={columns}
      pagination
      getRowId={(row) => row._id}
      // hideFooterRowCount={true}
      // hideFooter={true}
      // disableRowSelectionOnClick={true}
      autoHeight={false}
    />
  );
}

import { useCallback, useContext } from 'react';
import {
  useQuery,
  useMutation,
  useQueryClient,
  QueryKey,
  UseQueryOptions,
  UseMutationOptions,
} from '@tanstack/react-query';
import api from '@/lib/api-client';
import type { Company } from '@/types/api';
import { CompanyInput } from '@/types/formInput';
import { SnackBarContext } from 'pragmatic-ui';

export const companiesKey = ['companies'];

const getCompanies = async (): Promise<Company[]> => {
  const response = await api.get('/v1/admin/companies');
  return response.data;
};

export const useCompanies = (
  options?: Omit<
    UseQueryOptions<Company[], Error, Company[], QueryKey>,
    'queryKey' | 'queryFn'
  >,
) =>
  useQuery({
    queryKey: companiesKey,
    queryFn: getCompanies,
    ...options,
  });

async function createCompany(payload: CompanyInput): Promise<Company> {
  const response = await api.post('/v1/admin/companies', payload);
  return response.data;
}

export const useCreateCompany = (
  options?: Omit<
    UseMutationOptions<Company, Error, CompanyInput>,
    'mutationFn'
  >,
) => {
  const queryClient = useQueryClient();

  const setCompany = useCallback(
    (data: Company) => queryClient.setQueryData(companiesKey, data),
    [queryClient],
  );

  return useMutation({
    mutationFn: createCompany,
    ...options,
    onSuccess: (company, ...rest) => {
      setCompany(company);
      queryClient.invalidateQueries({ queryKey: companiesKey });
      options?.onSuccess?.(company, ...rest);
    },
  });
};

async function updateCompany(
  updatedCompany: Partial<Company>,
): Promise<Company> {
  const response = await api.put(
    `/v1/admin/companies/${updatedCompany?._id}`,
    updatedCompany,
  );
  return response.data;
}

export const useUpdateCompany = (
  _options?: Omit<
    UseMutationOptions<Company, Error, Partial<Company>>,
    'mutationFn'
  >,
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: updateCompany,
    onError: (_err) => {
      const { showSnack } = useContext(SnackBarContext);
      showSnack('There was an error updating the company', 'error');
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: companiesKey });
      const { showSnack } = useContext(SnackBarContext);
      showSnack('Successfully updated company', 'success');
    },
  });
};

async function deleteCompany(id: string): Promise<void> {
  const response = await api.delete(`v1/admin/companies/${id}`);
  return response.data;
}

export const useDeleteCompany = (
  options?: Omit<UseMutationOptions<void, Error, string>, 'mutationFn'>,
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deleteCompany,
    ...options,
    onSuccess: (...args) => {
      queryClient.invalidateQueries({ queryKey: companiesKey });
      options?.onSuccess?.(...args);

      //const { showSnack } = useContext(SnackBarContext);
      //showSnack('Successfully deleted company', 'success');
    },
  });
};

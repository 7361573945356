/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from 'react';
import { darken, Drawer, Fab } from '@mui/material';
import Typography from '@mui/material/Typography';
import PencilOutlinedIcon from '@/components/icons/PencilOutlinedIcon';
import { Box } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import UpdateCompanyForm from '@/features/dashboard/UpdateCompanyForm';

export default function UpdateClientDrawer({ company }: any) {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = (open: any) => (event: any) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  return (
    <>
      <Fab
        color="primary"
        onClick={() => setDrawerOpen(true)}
        size="small"
        aria-label="add"
        sx={{
          mr: 2,
          '&:hover': {
            backgroundColor: darken('#F6AE2D', 0.2),
          },
        }}
      >
        <PencilOutlinedIcon />
      </Fab>
      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={toggleDrawer(false)}
        PaperProps={{
          sx: {
            width: '30% !important',
            background: '#202D38',
            borderRadius: '12px 0px 0px 12px !important',
            color: '#fff',
          },
        }}
      >
        <div role="presentation">
          <Box
            component="div"
            display="flex"
            m={3}
            justifyContent="start"
            alignItems={'center'}
            onClick={toggleDrawer(false)}
          >
            <ArrowBackIcon sx={{ cursor: 'pointer', mr: 3 }}></ArrowBackIcon>
            <Typography variant="h6" sx={{ color: '#fff' }}>
              Edit Client
            </Typography>
          </Box>
          <Box
            m={10}
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{ mt: -1 }}
          >
            <UpdateCompanyForm
              company={company}
              toggleDrawer={() => setDrawerOpen(!open)}
            />
          </Box>
        </div>
      </Drawer>
    </>
  );
}

export default function StartLocationIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23"
      height="23"
      viewBox="0 0 23 23"
      fill="none"
    >
      <path
        d="M11.2998 22.0661C17.0904 22.0661 21.7998 17.452 21.7998 11.7416C21.7998 6.03115 17.0904 1.41699 11.2998 1.41699C5.5092 1.41699 0.799805 6.03115 0.799805 11.7416C0.799805 17.452 5.5092 22.0661 11.2998 22.0661ZM17.2284 11.7416C17.2284 14.8527 14.6409 17.4947 11.2998 17.4947C7.95874 17.4947 5.37123 14.8527 5.37123 11.7416C5.37123 8.63041 7.95874 5.98842 11.2998 5.98842C14.6409 5.98842 17.2284 8.63041 17.2284 11.7416Z"
        fill="#C2EFEB"
        stroke="#C2EFEB"
      />
    </svg>
  );
}

/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useRef, useEffect } from 'react';
import { alpha } from '@mui/material/styles';
import {
  Box,
  Button,
  Grid,
  Paper,
  Typography,
  Fab,
  Divider,
  Menu,
  MenuItem,
  darken,
  InputLabel,
  styled,
  Link,
  CircularProgress,
  MenuProps,
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { FormikHelpers, Field, FieldArray, FormikProps } from 'formik';
import MyFormik from '@/components/Formik';
import {
  FileDropZone,
  PTextField,
  CurrentFiles,
  SnackBarContext,
} from 'pragmatic-ui';
import AddIcon from '@mui/icons-material/Add';
import SaveIcon from '@/components/icons/SaveIcon';
import { createVehicleSchema } from '@/lib/validation/vehicles';
import { useVehicles, useUpdateVehicle } from '@/hooks/useVehicles';
import { useCompanies } from '@/hooks/useCompanies';
import type { Vehicle } from '@/types/api';
import type {
  VehicleInputFormsValues,
  VehicleInputValues,
} from '@/types/types';
import ConfigurationsTable from './ConfigurationsTable';
import { useConfigurations } from '@/hooks/useConfigurations';
import { useContext } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { v4 as uuidV4 } from 'uuid';

const StyledForm = styled('form')(() => ({
  width: '100%',
}));

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'left',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 140,
    color:
      theme.palette.mode === 'light'
        ? 'rgb(55, 65, 81)'
        : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '8px 0',
      margin: '0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));

const FileRender = ({
  file,
  onRemoveFileClick,
}: {
  file: CurrentFiles;
  onStarClick: () => void;
  onRemoveFileClick: () => void;
  removeEntry?: boolean;
}) => {
  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{
          width: '100%',
        }}
      >
        <Typography variant="body1" color="primary" sx={{ mr: 4 }}>
          {file.filename}
        </Typography>
        <Button onClick={onRemoveFileClick}>Remove File</Button>
      </Box>
    </>
  );
};

export default function EditVehicleForm() {
  const formikRef = useRef<FormikProps<any>>(null);
  const fileUploadButtonRefs = useRef<(HTMLButtonElement | null)[]>([]);
  const { vehicleId } = useParams();
  const navigate = useNavigate();
  const { data: companies = [], isLoading: isCompanyDataLoading } =
    useCompanies();
  const { data: vehicles = [], isLoading: isVehicleDataLoading } =
    useVehicles();
  const { data: configurations = [], isLoading: isConfigurationsDataLoading } =
    useConfigurations();
  const updateVehicle = useUpdateVehicle();
  const { showSnack } = useContext(SnackBarContext);
  const [anchorMoreMenu, setAnchorMoreMenu] =
    React.useState<null | HTMLElement>(null);
  const openMoreMenu = Boolean(anchorMoreMenu);
  const [currentRow, setCurrentRow] = React.useState(0);
  const onClickMoreMenu = (
    event: React.MouseEvent<HTMLElement>,
    idx: number,
  ) => {
    setAnchorMoreMenu(event.currentTarget);
    setCurrentRow(idx);
  };
  const handleCloseMenu = () => {
    setAnchorMoreMenu(null);
  };

  const isLoading =
    isCompanyDataLoading || isVehicleDataLoading || isConfigurationsDataLoading;

  // useEffect(() => {
  //   console.log('fileUploadButtonRefs:', fileUploadButtonRefs);
  // }, [fileUploadButtonRefs]);

  // useEffect(() => {
  //   console.log('formikRef:', formikRef);
  // }, [formikRef]);

  if (isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  //console.log('vehicles:', vehicles);
  if(!Array.isArray(vehicles)) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  const vehicle = vehicles.find(
    (vehicle) => vehicle._id === vehicleId,
  ) as Vehicle;

  const handleSubmit = async (
    values: any,
    { resetForm }: FormikHelpers<VehicleInputValues>,
  ) => {
    const formData = new FormData();

    formData.append('_id', vehicleId || '');
    formData.append('company', values.company);
    formData.append('branch', values.branch);
    formData.append('rego', values.forms[0].rego);
    formData.append('fleetNumber', values.forms[0].fleetNumber);
    formData.append('make', values.forms[0].make);
    formData.append('model', values.forms[0].model);
    formData.append('vehicleType', values.forms[0].vehicleType);

    //console.log('values', values);
    values.forms[0].uploads.forEach((upload: any, uploadIndex: number) => {
      if (values[`files-${uploadIndex}`]) {
        const fileid = uuidV4();
        upload.fileid = fileid;
        formData.append(
          fileid,
          values[`files-${uploadIndex}`][0],
          values[`files-${uploadIndex}`][0].name,
        );
      }
    });

    // Convert uploads to JSON string and append
    formData.append('uploads', JSON.stringify(values.forms[0].uploads));

    try {
      await updateVehicle.mutateAsync(formData);
      //resetForm();
      showSnack('Vehicle updated successfully', 'success');
      //navigate('/vehicles/' + vehicleId, { replace: true });
    } catch (error) {
      showSnack('Error updating vehicle. Please try again later', 'error');
    }
  };

  const formikProps = {
    initialValues: {
      company: vehicle.company._id,
      branch: vehicle.branch,
      forms: [
        {
          rego: vehicle.rego,
          fleetNumber: vehicle.fleetNumber || '',
          make: vehicle.make || '',
          model: vehicle.model || '',
          vehicleType: vehicle.vehicleType,
          uploads: vehicle.uploads || [
            {
              name: '',
              description: '',
              uploads: [],
            },
          ],
        },
      ],
    },
    validationSchema: createVehicleSchema,
    onSubmit: handleSubmit,
  };

  const onClickRemoveRow = () => {
    if (formikRef.current) {
      handleCloseMenu();
      const { values, setFieldValue } = formikRef.current;
      const updatedForms = values.forms[0].uploads.filter(
        (_: any, idx: number) => idx !== currentRow,
      );
      setFieldValue(`forms.${0}.uploads`, updatedForms);
    }
  };

  const onClickRemoveFile = () => {
    if (formikRef.current) {
      formikRef.current.setFieldValue(
        `forms.${0}.uploads.${currentRow}.file`,
        null,
      );
      const { values, setFieldValue } = formikRef.current;
      if (values[`files-${currentRow}`]) {
        setFieldValue(`files-${currentRow}`, undefined);
      }
    }
    handleCloseMenu();
  };

  const onClickAddFile = () => {
    if (formikRef.current) {
      if (fileUploadButtonRefs.current[currentRow]) {
        const { values } = formikRef.current;
        if (!values[`files-${currentRow}`]) {
          fileUploadButtonRefs.current[currentRow].click(); // Programmatically click the button
        }
      }
    }
    handleCloseMenu();
  };

  return (
    <Box px={4}>
      <MyFormik<any> innerRef={formikRef} {...formikProps}>
        {({ handleSubmit, values, setFieldValue }) => (
          <>
            <StyledForm onSubmit={handleSubmit}>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box display="flex" alignItems="center">
                  <PTextField
                    name="company"
                    size="small"
                    variant="standard"
                    label="Client"
                    sx={{
                      mr: 4,
                      mt: 1,
                      minWidth: '150px',
                    }}
                    InputProps={{
                      disableUnderline: false,
                    }}
                    className="config-text-fields"
                    select
                    SelectProps={{
                      multiple: false,
                      label: 'Clients',
                      sx: {
                        '& .MuiSvgIcon-root': {
                          color: '#F6AE2D',
                        },
                      },
                    }}
                  >
                    {companies.map((company) => {
                      return (
                        <MenuItem
                          key={`client-${company._id}`}
                          value={company._id}
                        >
                          {company.name}
                        </MenuItem>
                      );
                    })}
                  </PTextField>
                  <PTextField
                    name="branch"
                    label="Branch"
                    variant="standard"
                    className="config-text-fields"
                    sx={{
                      mr: 4,
                      mt: 1,
                      minWidth: '150px',
                    }}
                    InputProps={{
                      disableUnderline: false,
                    }}
                    select
                    SelectProps={{
                      multiple: false,
                      sx: {
                        '& .MuiSvgIcon-root': {
                          color: '#F6AE2D',
                        },
                      },
                    }}
                  >
                    {values.company
                      ? companies
                          .find((company) => company._id === values.company)
                          ?.branches.map((branch: any, i: number) => (
                            <MenuItem
                              key={`branch-${i}-${branch}`}
                              value={branch}
                            >
                              {branch}
                            </MenuItem>
                          ))
                      : [].map(() => (
                          <MenuItem key={`default`} value={''}></MenuItem>
                        ))}
                  </PTextField>
                </Box>
                <Box display="flex" alignItems="center">
                  <Button
                    size="small"
                    sx={{
                      textTransform: 'uppercase',
                      fontWeight: '700',
                      mr: 2,
                    }}
                    onClick={() => navigate('/vehicles')}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    size="small"
                    color="primary"
                    startIcon={<SaveIcon />}
                    sx={{ textTransform: 'uppercase', fontWeight: '700' }}
                  >
                    Save
                  </Button>
                </Box>
              </Box>
              <>
                <FieldArray name="forms">
                  {() => (
                    <>
                      {values.forms.map(
                        (form: VehicleInputFormsValues, index: number) => (
                          <Box pt={8} key={index}>
                            <Grid container columnSpacing={8}>
                              <Grid item xs={2.3}>
                                <PTextField
                                  variant="standard"
                                  name={`forms.${index}.rego`}
                                  placeholder="Rego No."
                                  InputProps={{
                                    disableUnderline: false,
                                  }}
                                  fullWidth
                                />
                              </Grid>

                              <Grid item xs={2.4}>
                                <PTextField
                                  variant="standard"
                                  name={`forms.${index}.fleetNumber`}
                                  placeholder="Fleet No."
                                  fullWidth
                                  InputProps={{
                                    disableUnderline: false,
                                  }}
                                />
                              </Grid>

                              <Grid item xs={2.4}>
                                <PTextField
                                  variant="standard"
                                  name={`forms.${index}.make`}
                                  placeholder="Make"
                                  InputProps={{
                                    disableUnderline: false,
                                  }}
                                  fullWidth
                                />
                              </Grid>

                              <Grid item xs={2.4}>
                                <PTextField
                                  variant="standard"
                                  name={`forms.${index}.model`}
                                  placeholder="Model"
                                  InputProps={{
                                    disableUnderline: false,
                                  }}
                                  fullWidth
                                />
                              </Grid>

                              <Grid item xs={2.4}>
                                <PTextField
                                  variant="standard"
                                  name={`forms.${index}.vehicleType`}
                                  placeholder="Vehicle Type"
                                  InputProps={{
                                    disableUnderline: false,
                                  }}
                                  select
                                  SelectProps={{
                                    multiple: false,
                                    label: 'Vehicle Type',
                                    placeholder: 'Vehicle Type',
                                    sx: {
                                      '& .MuiSvgIcon-root': {
                                        color: '#F6AE2D',
                                      },
                                    },
                                  }}
                                  fullWidth
                                >
                                  {['Mobile Crane', 'Trailer', 'Tractor'].map(
                                    (vehicleType: string, i) => (
                                      <MenuItem
                                        key={`vehicle-${i}`}
                                        value={vehicleType}
                                        selected={
                                          vehicle.vehicleType === vehicleType
                                        }
                                      >
                                        {vehicleType}
                                      </MenuItem>
                                    ),
                                  )}
                                </PTextField>
                              </Grid>

                              <FieldArray name={`forms.${index}.uploads`}>
                                {({ remove: removeEntireRow, push }: any) => (
                                  <>
                                    <Grid item xs={9} mt={3}>
                                      <Typography
                                        variant="h6"
                                        sx={{ color: '#EAEAEA' }}
                                      >
                                        Supporting documents
                                      </Typography>

                                      <Paper
                                        component="div"
                                        sx={{
                                          py: 3,
                                          pl: 4,
                                          pr: 1,
                                          mt: 4,
                                          borderRadius: '16px',
                                        }}
                                      >
                                        {form.uploads?.length ? (
                                          <>
                                            {form?.uploads?.map(
                                              (upload: any, idx) => (
                                                <Grid
                                                  container
                                                  columnSpacing={4}
                                                  key={`vehicle-uploads-${idx}`}
                                                >
                                                  <Grid item xs={3}>
                                                    <Field
                                                      name={`forms.${index}.uploads.${idx}.name`}
                                                    >
                                                      {({ field }: any) => (
                                                        <PTextField
                                                          {...field}
                                                          label={
                                                            idx == 0 && 'NAME'
                                                          }
                                                          placeholder="Add Name"
                                                          fullWidth
                                                        />
                                                      )}
                                                    </Field>
                                                  </Grid>

                                                  <Grid item xs={3}>
                                                    <Field
                                                      name={`forms.${index}.uploads.${idx}.description`}
                                                    >
                                                      {({ field }: any) => (
                                                        <PTextField
                                                          {...field}
                                                          label={
                                                            idx == 0 &&
                                                            'DESCRIPTION'
                                                          }
                                                          placeholder="Description"
                                                          fullWidth
                                                        />
                                                      )}
                                                    </Field>
                                                  </Grid>
                                                  {upload.file?.signedUrl ? (
                                                    <Grid item xs={5}>
                                                      <InputLabel
                                                        sx={{
                                                          color: '#fff',
                                                          height: '20px',
                                                        }}
                                                      >
                                                        {idx == 0 && 'UPLOADS'}
                                                      </InputLabel>
                                                      <Link
                                                        className="uploadedfile"
                                                        target="_blank"
                                                        href={
                                                          upload.file?.signedUrl
                                                        }
                                                        download={
                                                          upload.file.filename
                                                        }
                                                        sx={{
                                                          color: 'primary',
                                                          mt: 5,
                                                          display: 'flex',
                                                          justifyContent:
                                                            'flex-start',
                                                          alignItems: 'center',
                                                        }}
                                                      >
                                                        {upload.file.filename}
                                                      </Link>
                                                    </Grid>
                                                  ) : (
                                                    <Grid item xs={5}>
                                                      <InputLabel
                                                        sx={{
                                                          color: '#fff',
                                                          height: '20px',
                                                        }}
                                                      >
                                                        {idx == 0 && 'UPLOADS'}
                                                      </InputLabel>
                                                      <FileDropZone
                                                        name={`files-${idx}`}
                                                        maxFiles={1}
                                                        featured
                                                        fileFormat={{
                                                          'application/pdf': [
                                                            '.pdf',
                                                          ],
                                                        }}
                                                        renderTitle={(openFilePicker) => (
                                                          <>
                                                            <Typography
                                                              variant="caption"
                                                              sx={{
                                                                fontSize:
                                                                  '14px',
                                                                ml: -7,
                                                              }}
                                                              onClick={openFilePicker}
                                                            >
                                                              Drag and drop to
                                                              upload (max file
                                                              size: 50 MB)
                                                            </Typography>
                                                          </>
                                                        )}
                                                        renderButton={(
                                                          openFilePicker,
                                                        ) => (
                                                          <>
                                                            <Button
                                                              ref={(el) =>
                                                                (fileUploadButtonRefs.current[
                                                                  idx
                                                                ] = el)
                                                              }
                                                              variant="contained"
                                                              color="primary"
                                                              sx={{
                                                                display: 'none',
                                                                mt: 2,
                                                                textTransform:
                                                                  'uppercase',
                                                              }}
                                                              onClick={
                                                                openFilePicker
                                                              }
                                                            >
                                                              Upload
                                                            </Button>
                                                          </>
                                                        )}
                                                        renderFile={(
                                                          file,
                                                          onStarClick,
                                                          onRemoveFileClick,
                                                        ) => {
                                                          return (
                                                            <FileRender
                                                              file={file}
                                                              onStarClick={
                                                                onStarClick
                                                              }
                                                              onRemoveFileClick={
                                                                onRemoveFileClick
                                                              }
                                                            />
                                                          );
                                                        }}
                                                        containerSx={{
                                                          background:
                                                            'transparent',
                                                          px: 0,
                                                          mx: 0,
                                                        }}
                                                      />
                                                    </Grid>
                                                  )}
                                                  <Grid
                                                    item
                                                    xs={1}
                                                    display="flex"
                                                    container
                                                    direction={'column'}
                                                    justifyContent={'center'}
                                                  >
                                                    {!upload.isNew && (
                                                      <Fab
                                                        onClick={(event) => {
                                                          console.log(upload);
                                                          onClickMoreMenu(
                                                            event,
                                                            idx,
                                                          );
                                                        }}
                                                        color="primary"
                                                        size="small"
                                                        sx={{
                                                          mr: 2,
                                                          '&:hover': {
                                                            backgroundColor:
                                                              darken(
                                                                '#F6AE2D',
                                                                0.2,
                                                              ),
                                                          },
                                                        }}
                                                      >
                                                        <MoreVertIcon />
                                                      </Fab>
                                                    )}
                                                    {upload.isNew && (
                                                      <Fab
                                                        onClick={() => {
                                                          removeEntireRow(idx);
                                                        }}
                                                        color="secondary"
                                                        size="small"
                                                        sx={{
                                                          mr: 2,
                                                          '&:hover': {
                                                            backgroundColor:
                                                              darken(
                                                                '#F6AE2D',
                                                                0.2,
                                                              ),
                                                          },
                                                        }}
                                                      >
                                                        <CloseIcon />
                                                      </Fab>
                                                    )}
                                                  </Grid>
                                                </Grid>
                                              ),
                                            )}
                                          </>
                                        ) : null}
                                      </Paper>
                                    </Grid>
                                    <Grid
                                      item
                                      xs={3}
                                      display="flex"
                                      container
                                      direction={'column'}
                                      justifyContent={'flex-end'}
                                    >
                                      <Box
                                        display="flex"
                                        justifyContent="flex-start"
                                        alignContent="center"
                                        alignItems="center"
                                        sx={{
                                          height: '50px',
                                        }}
                                      >
                                        <Fab
                                          color="primary"
                                          size="small"
                                          sx={{
                                            mr: 2,
                                            '&:hover': {
                                              backgroundColor: darken(
                                                '#F6AE2D',
                                                0.2,
                                              ),
                                            },
                                          }}
                                          onClick={() =>
                                            push({
                                              name: '',
                                              description: '',
                                              isNew: true,
                                            })
                                          }
                                        >
                                          <AddIcon />
                                        </Fab>
                                        <Typography sx={{ color: '#F6AE2D' }}>
                                          Add Another Document
                                        </Typography>
                                      </Box>
                                    </Grid>
                                  </>
                                )}
                              </FieldArray>
                            </Grid>

                            <Grid container mt={8} columnSpacing={4}>
                              <Grid item xs={12}>
                                <Divider
                                  component="div"
                                  sx={{ backgroundColor: '#BD3825' }}
                                />
                              </Grid>
                            </Grid>
                          </Box>
                        ),
                      )}
                    </>
                  )}
                </FieldArray>

                <StyledMenu
                  id="moreMenu"
                  anchorEl={anchorMoreMenu}
                  open={openMoreMenu}
                  onClose={handleCloseMenu}
                >
                  <MenuItem onClick={onClickAddFile} disableRipple>
                    Add file
                  </MenuItem>
                  <MenuItem onClick={onClickRemoveFile} disableRipple>
                    Remove file
                  </MenuItem>
                  <MenuItem onClick={onClickRemoveRow} disableRipple>
                    Delete entire row
                  </MenuItem>
                </StyledMenu>
              </>
            </StyledForm>
          </>
        )}
      </MyFormik>
      {vehicle.vehicleType !== 'Trailer' ? (
        <ConfigurationsTable
          configurations={configurations?.filter(
            (configuration) => configuration.vehicle === vehicle._id,
          )}
        />
      ) : null}
    </Box>
  );
}

import { useConfigurations } from '@/hooks/useConfigurations';
import {
  Box,
  Chip,
  CircularProgress,
  Grid,
  MenuItem,
  Paper,
  Select,
  Typography,
} from '@mui/material';
import { useParams } from 'react-router-dom';
import CustomMap from '@/components/Map';
import MobileCraneIcon from '@/components/icons/MobileCraneIcon';
import ArrowDownIcon from '@/components/icons/ArrowDownIcon';
import { useEffect, useState } from 'react';
import { useVehicles } from '@/hooks/useVehicles';
import { Vehicle } from '@/types/api';
import ArrowUpIcon from '@/components/icons/ArrowUpIcon';
import MapWithAutocomplete from '@/components/MapWithAutocomplete';

export default function ViewConfiguration() {
  const { companyId } = useParams();
  const { data: configurations, isLoading: isConfigurationsLoading } =
    useConfigurations();
  const { data: vehicles, isLoading: isVehiclesLoading } = useVehicles();
  const isLoading = isConfigurationsLoading || isVehiclesLoading;

  const [selectedVehicle, setSelectedVehicle] = useState<Vehicle | null>(null);
  const [selectedConfiguration, setSelectedConfiguration] = useState<any>(null);
  const [openedVehicleId, setOpenedVehicleId] = useState<string | null>(null);

  const filteredVehicles =
    vehicles?.filter((v) => v.company._id === companyId) ?? [];

  useEffect(() => {
    if (filteredVehicles.length > 0 && !selectedVehicle) {
      setSelectedVehicle(filteredVehicles[0]);
    }
  }, [filteredVehicles, selectedVehicle]);

  useEffect(() => {
    if (selectedVehicle) {
      const configurationsForVehicle = configurations?.filter(
        (c) => c.vehicle === selectedVehicle._id,
      );
      if (configurationsForVehicle && configurationsForVehicle.length > 0) {
        setSelectedConfiguration(configurationsForVehicle[0]);
      } else {
        setSelectedConfiguration(null);
      }
    }
  }, [selectedVehicle, configurations]);

  if (isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  const route =
    selectedConfiguration?.permits &&
    selectedConfiguration.permits[0] &&
    selectedConfiguration.permits[0].route;

  return (
    <Box>
      <Grid container spacing={1}>
        <Grid item xs={4}>
          <Box>
            <Box
              px={3}
              display="flex"
              justifyContent="start"
              alignItems="center"
              sx={{ marginTop: '-10px' }}
            >
              <Typography sx={{ fontSize: '14px', textAlign: 'center' }}>
                VEHICLE CONFIGURATIONS
              </Typography>
            </Box>
            {filteredVehicles.map((vehicle) => {
              // todo: extract this into another component
              const configurationsForVehicle = configurations?.filter(
                (c) => c.vehicle === vehicle._id,
              );

              const isVehicleSelected = selectedVehicle?._id === vehicle._id;
              const isVehicleOpened = openedVehicleId === vehicle._id;

              return (
                <Paper
                  key={vehicle._id}
                  sx={{
                    width: '99%',
                    background: '#334657',
                    borderRadius: '6px',
                    border: `0.5px solid ${isVehicleSelected ? '#F6AE2D' : 'transparent'}`,
                    paddingY: '15px',
                    mt: 1,
                    ml: 1,
                    cursor: 'pointer',
                  }}
                >
                  <Box
                    component="div"
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    px={2}
                    onClick={() => {
                      setSelectedVehicle(vehicle);
                      setOpenedVehicleId(vehicle._id);
                    }}
                  >
                    <Box display="flex" alignItems="center">
                      <MobileCraneIcon />
                    </Box>
                    <Box display="flex" alignItems="center" flexGrow={1} ml={2}>
                      <Chip
                        sx={{
                          background: '#fff',
                          borderRadius: '4px',
                          width: '100px',
                        }}
                        label={
                          <Typography
                            variant="subtitle1"
                            sx={{
                              color: '#202D38',
                            }}
                          >
                            {vehicle.rego || 'N/A'}
                          </Typography>
                        }
                      />
                      <Typography sx={{ ml: 2, fontSize: '12px' }}>
                        FLEET: #{vehicle.fleetNumber || 'N/A'}{' '}
                      </Typography>
                    </Box>

                    {/* Right Icon */}
                    <Box
                      display="flex"
                      alignItems="center"
                      onClick={(e) => {
                        e.stopPropagation();
                        setOpenedVehicleId(
                          isVehicleOpened ? null : vehicle._id,
                        );
                      }}
                    >
                      {isVehicleOpened ? <ArrowUpIcon /> : <ArrowDownIcon />}
                    </Box>
                  </Box>

                  {/* Configurations Dropdown */}
                  {isVehicleOpened &&
                    configurationsForVehicle &&
                    configurationsForVehicle.length > 0 && (
                      <Box px={2} mt={2}>
                        <Select
                          variant="filled"
                          fullWidth
                          value={selectedConfiguration?._id || ''}
                          onChange={(e) => {
                            const config = configurationsForVehicle.find(
                              (c) => c._id === e.target.value,
                            );
                            setSelectedConfiguration(config);
                          }}
                          sx={{
                            '& .MuiSvgIcon-root': {
                              color: 'primary.main', // Replace with your desired color
                            },
                          }}
                        >
                          {configurationsForVehicle.map((config) => (
                            <MenuItem key={config._id} value={config._id}>
                              {config.name || `Configuration ${config._id}`}
                            </MenuItem>
                          ))}
                        </Select>
                      </Box>
                    )}
                </Paper>
              );
            })}
          </Box>
        </Grid>
        <Grid item xs={8} sx={{ paddingTop: 0, marginTop: '-20px' }}>
          {route ? (
            // <CustomMap geojsonData={route} />
            <MapWithAutocomplete geojsonData={route} />
          ) : (
            <Typography sx={{ mt: 2 }}>No geojson data available.</Typography>
          )}
        </Grid>
      </Grid>
    </Box>
  );
}

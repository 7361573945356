export default function () {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
    >
      <path
        d="M20.2612 9.85254V7.85254C20.2612 6.74797 19.3658 5.85254 18.2612 5.85254H6.26123C5.15666 5.85254 4.26123 6.74797 4.26123 7.85254V19.8525C4.26123 20.9571 5.15666 21.8525 6.26123 21.8525H9.26123M15.2612 3.85254V7.85254M9.26123 3.85254V7.85254M4.26123 11.8525H9.26123M17.2612 13.8525V21.8525M13.2612 17.8525H21.2612"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

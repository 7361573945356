export default function () {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
    >
      <path
        d="M20.1 7.7002L4.09998 7.70019M4.09998 7.70019L8.09998 3.70019M4.09998 7.70019L8.09998 11.7002M4.09997 17.7002L20.1 17.7002M20.1 17.7002L16.1 13.7002M20.1 17.7002L16.1 21.7002"
        stroke="#F6AE2D"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

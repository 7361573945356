export default function MapIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
    >
      <path
        d="M9.80017 19.1162V4.11621"
        stroke="#202D38"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.8002 14.1162V21.0125"
        stroke="#202D38"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.8002 13.1162V18.4483C21.8011 18.8781 21.5265 19.2602 21.1187 19.3962C19.8214 19.8287 17.0428 20.7551 16.1116 21.0656C15.9078 21.1336 15.6873 21.133 15.4838 21.064L10.1164 19.2747C9.91114 19.2063 9.6892 19.2063 9.48391 19.2747L5.11643 20.7307C4.81148 20.8324 4.47625 20.7812 4.21548 20.5933C3.9547 20.4053 3.80017 20.1035 3.80017 19.782V6.72897C3.80017 6.29855 4.07559 5.91642 4.48391 5.7803L9.16764 4.21886C9.5782 4.08199 10.0221 4.08199 10.4326 4.21886L11.8002 4.67473"
        stroke="#202D38"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.8002 6.61621C14.8002 8.25472 16.5882 10.005 17.6082 10.8706C18.011 11.1981 18.5883 11.1981 18.9912 10.8706C20.0122 10.005 21.8002 8.25472 21.8002 6.61621"
        stroke="#202D38"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.3012 6.36622C18.4391 6.36677 18.5505 6.47894 18.5502 6.61688C18.5498 6.75482 18.4378 6.8664 18.2998 6.86622C18.1619 6.86603 18.0502 6.75416 18.0502 6.61622C18.0498 6.54963 18.0762 6.48569 18.1234 6.4387C18.1705 6.39171 18.2346 6.3656 18.3012 6.36622"
        stroke="#202D38"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.8002 6.61621V6.61621C14.8002 4.68321 16.3672 3.11621 18.3002 3.11621V3.11621C20.2332 3.11621 21.8002 4.68321 21.8002 6.61621V6.61621"
        stroke="#202D38"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

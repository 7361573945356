export default function HidePointsIcon({ style, circleStroke = 'white' }: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="27"
      viewBox="0 0 26 27"
      fill="none"
      style={style}
    >
      <path
        d="M22.6208 9.94987C22.5395 9.60852 22.4208 9.27718 22.2669 8.96187C20.4735 5.51551 16.8844 3.3807 13 3.44987C9.11559 3.38071 5.52651 5.51552 3.73317 8.96187V8.96187C3.08894 10.2676 3.08894 11.7988 3.73317 13.1045V13.1045C5.33975 16.2082 8.43304 18.2642 11.9167 18.5438"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.25 18.0749C16.25 15.9808 17.9476 14.2832 20.0417 14.2832C22.1357 14.2832 23.8333 15.9808 23.8333 18.0749C23.8333 19.8499 21.8963 21.7461 20.7913 22.6837C20.3549 23.0386 19.7295 23.0386 19.2931 22.6837C18.187 21.7461 16.25 19.8499 16.25 18.0749Z"
        fill="black"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        cx="13"
        cy="11.0339"
        r="2.16667"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.4141 18.0684L21.6699 18.0684"
        stroke={circleStroke}
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
}

export default function EndLocationIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.1687 23.2729C15.8077 21.1024 22 15.5462 22 11.2002C22 5.67735 17.5228 1.2002 12 1.2002C6.47715 1.2002 2 5.67735 2 11.2002C2 15.5462 8.19234 21.1024 10.8313 23.2729C11.5162 23.8362 12.4838 23.8362 13.1687 23.2729ZM12 14.2002C13.6569 14.2002 15 12.857 15 11.2002C15 9.54334 13.6569 8.2002 12 8.2002C10.3431 8.2002 9 9.54334 9 11.2002C9 12.857 10.3431 14.2002 12 14.2002Z"
        fill="#F6AE2D"
      />
    </svg>
  );
}

/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import { darken, Pagination } from '@mui/material';
import {
  DataGridPro,
  DataGridProProps,
  GridToolbar,
  GridPaginationModel,
} from '@mui/x-data-grid-pro';

type CustomDataGridProps = DataGridProProps<any> & {
  filter?: boolean;
  search?: boolean;
};

export default function CustomDataGrid(props: CustomDataGridProps) {
  const { filter = true, search = true, rows=[] } = props;

  return (
    <DataGridPro
      {...props}
      disableRowSelectionOnClick
      rowHeight={75}
      autoHeight={true}
      sx={{
        mt: -4,
        '&, [class^=MuiDataGrid]': { border: 'none' },
        '.MuiDataGrid-columnHeaderDraggableContainer': { border: 'none' },
        '.MuiDataGrid-row--borderBottom .MuiDataGrid-columnHeader': {
          border: 'none',
        },
        '.MuiDataGrid-columnSeparator': {
          display: 'none',
        },
        border: 'none',
        bottom: 0,
        '.MuiDataGrid-columnHeaders': {
          '.MuiDataGrid-columnHeaderTitle': {
            textTransform: 'uppercase',
          },
        },
        '& .MuiDataGrid-sortIcon': {
          color: '#fff !important',
        },
        '& .MuiDataGrid-cell': {
          fontWeight: '400',
          border: 'none',
          '&:hover': {
            color: '#fff',
            backgroundColor: darken('#202D38', 0.1),
            borderBottom: '0.4px solid rgba(224, 224, 224, 0.2)',
          },
        },
        '& .MuiDataGrid-row': {
          backgroundColor: '#202D38',
          borderBottom: '0.5px solid rgba(224, 224, 224, 0.2)',
          '&:last-child': {
            borderBottom: 'none',
          },
          '&:hover': {
            cursor: 'pointer',
            color: '#fff',
            backgroundColor: darken('#202D38', 0.1),
            borderBottom: '0.5px solid rgba(224, 224, 224, 0.2)',
          },
        },
        '& .MuiIconButton-root': {
          // Targeting the IconButton specifically
          color: '#fff', // Change this to your desired color
        },
        '& .MuiListItemIcon-root': {
          color: '#fff',
        },
        '& .MuiInput-underline': {
          // This targets the underline of the input
          position: 'relative',
          top: '-15px',
        },
        '& .MuiInput-underline:before': {
          // underline color when input is not focused
          borderBottomColor: '#fff !important',
        },
        '& .MuiDataGrid-cell:focus, & .MuiDataGrid-columnHeader:focus': {
          outline: 'none',
        },
        '& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-columnHeader:focus-within':
          {
            outline: 'none',
          },
        '& .MuiDataGrid-toolbarContainer button:focus': {
          outline: 'none',
        }
      }}
      slots={
        filter
          ? {
              toolbar: GridToolbar,
            }
          : {}
      }
      slotProps={
        search
          ? {
              toolbar: {
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 500 },
              },
            }
          : {}
      }
    />
  );
}
